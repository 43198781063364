// @ts-nocheck
/* eslint-disable no-undef */
/* eslint-disable import/group-exports */
/* eslint-disable import/exports-last */
const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
const compareDate = new Date(process?.env?.NEXT_PUBLIC_COMPARE_DATE ?? '2024-07-11T00:00:00.000+05:30');

export const hostEventUrl = process?.env?.NEXT_PUBLIC_HOST_EVENT_URL || "https://dev-events.konfhub.com";
export const certhubUrl = process?.env?.NEXT_PUBLIC_CERTHUB_URL || "https://dev-cert.konfhub.com";
export const quizhubUrl = process?.env?.NEXT_PUBLIC_QUIZHUB_URL || "https://dev-quiz.konfhub.com";
export const baseUrl = process?.env?.NEXT_PUBLIC_BASE_URL || "https://localhost:3000";
export const loginUrl = process?.env?.NEXT_PUBLIC_LOGIN_URL || "https://dev-accounts.konfhub.com";
export const gMapApiKey = "AIzaSyBkCKa3Rg5BeGbk2uxvOVFEbLcxNulxLOc";
export const GoogleAnalyticsId = process?.env?.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || "G-02TDXKQYLG";
export const ClarityId = process?.env?.NEXT_PUBLIC_CLARITY_ID || "hez36l9h0e";
export const s3Url = `https://konfhub-image-files-${process?.env?.NEXT_PUBLIC_ENV_NAME}.s3.${process?.env?.NEXT_PUBLIC_AWS_PROJECT_REGION}.amazonaws.com`;
export const mediaUrl = process?.env?.NEXT_PUBLIC_MEDIA_URL || "https://dev-media.konfhub.com";
export const xApiKey = process?.env?.NEXT_PUBLIC_CAPTURE_X_API_KEY || "d2252ec3-bf55-4d62-bf6e-d9dff52b6172";
export const publicStripePublishableKey = process?.env?.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "pk_test_51Nx4nbSBwIcmEW9pktp33m6yaXqdSgxS8H4XBAG2CKxWE3FteP6oaLWyKDKCAxE6JgYYiE4TzW7b2Hdr5MC81mUb00W21j4XfU";
export const facebook_id = process?.env?.NEXT_PUBLIC_FACEBOOK_APP_ID || "429779859341568";


const maintenanceStartDate = new Date(
    "2024-10-31T03:20:00.000+05:30"
);
const maintenanceEndDate = new Date(
    "2024-10-31T03:30:00.000+05:30"
);
const logoEndDate = new Date(
    "2024-12-28T00:00:00.000+05:30"
);

export const isMaintenanceTime =
    currentDate > maintenanceStartDate && currentDate < maintenanceEndDate;

export const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src="https://checkout.razorpay.com/v1/checkout.js"]`);
        if (existingScript) {
            resolve();
            return;
        }
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
};

