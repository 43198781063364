/* eslint-disable no-undef */
import { baseUrl } from "./config";

const awsconfig = {
    aws_project_region: process?.env?.NEXT_PUBLIC_AWS_PROJECT_REGION || "ap-southeast-1",
    aws_cognito_identity_pool_id: process?.env?.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID || "ap-southeast-1:9a5331da-329a-497d-be61-15668b42bf8f",
    aws_cognito_region: process?.env?.NEXT_PUBLIC_AWS_COGNITO_REGION || "ap-southeast-1",
    aws_user_pools_id: process?.env?.NEXT_PUBLIC_AWS_USER_POOLS_ID || "ap-southeast-1_f6tfrmIsS",
    aws_user_pools_web_client_id: process?.env?.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID || "1cum79568pe0u434ek1d5kin9a",
    "authenticationFlowType": 'USER_SRP_AUTH',
    oauth: {
        domain: process?.env?.NEXT_PUBLIC_OAUTH_DOMAIN || "dev-auth.konfhub.com",
        redirectSignIn: baseUrl,
        scope: ["email", "openid", "aws.cognito.signin.user.admin"],
        redirectSignOut: baseUrl,
        responseType: "token"
    },

    cookieStorage: {
        domain: process?.env?.NEXT_PUBLIC_HOST_NAME || "localhost",
        secure: false,
        path: "/",
        expires: 365
    }
};

export default awsconfig;
